import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  TOOLTIP_POSITIONS,
  TOOLTIP_VARIANTS
} from 'dpl/common/utils/constants';
import {
  PORTAL_MOUNT_EL,
  INITIAL_POSITION,
  getTooltipPosition
} from 'dpl/common/tooltip/components/FloatingTooltipWrapper';
import FloatingTooltip from 'dpl/common/tooltip/components/FloatingTooltip';

export default function UserFollowSuccessPopover({
  children,
  isVisible,
  title,
  targetEl,
  portalStyle
}) {
  const anchorRef = useRef(null);
  const tooltipRef = useRef(null);

  const [portalPosition, setPortalPosition] = useState(INITIAL_POSITION);
  const [leftOffset, setLeftOffset] = useState(0);

  useEffect(() => {
    if (!isVisible) {
      window.setTimeout(() => {
        setPortalPosition(INITIAL_POSITION);
      }, 300);
    } else {
      const {
        leftOffset: tooltipLeftOffset,
        portalPosition: tooltipPortalPosition
      } = getTooltipPosition(
        targetEl,
        anchorRef.current,
        tooltipRef.current,
        TOOLTIP_POSITIONS.BOTTOM_CENTER
      );

      setLeftOffset(tooltipLeftOffset);
      setPortalPosition(tooltipPortalPosition);
    }
  }, [isVisible]);

  return (
    <div className="UserFollowSuccessPopover FloatingTooltipWrapper dib">
      <div className="pointer flex" ref={anchorRef}>
        {children}
      </div>
      {createPortal(
        <div
          className="z-max absolute"
          style={{
            left: `${portalPosition.left}px`,
            top: `${portalPosition.top}px`,
            width: `${portalPosition.width}px`,
            ...portalStyle
          }}
        >
          <div
            style={{ marginLeft: `${leftOffset}px` }}
            className={classnames('mt2 min-w-100 tc transform-center-x', {
              'pointer-events-none': !isVisible
            })}
          >
            <div
              className={classnames('transition nowrap', {
                'FloatingTooltipWrapper__tooltip--visible': isVisible,
                FloatingTooltipWrapper__tooltip: !isVisible
              })}
            >
              <FloatingTooltip
                setRef={tooltipRef}
                arrowOffset={leftOffset}
                omitArrow
                title={title}
                minWidth="200px"
                variant={TOOLTIP_VARIANTS.LIGHT}
              />
            </div>
          </div>
        </div>,
        targetEl
      )}
    </div>
  );
}

UserFollowSuccessPopover.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool,
  title: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  targetEl: PropTypes.any,
  portalStyle: PropTypes.object
};

UserFollowSuccessPopover.defaultProps = {
  isVisible: false,
  targetEl: PORTAL_MOUNT_EL,
  portalStyle: null
};
